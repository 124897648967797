<template>
  <b-row class="mb-2">
    <b-col>
          <span
              class="text-muted p-1 mb-2">Enter a project name and click the Add button to create a new project.</span>
      <InfoIcon message="Enter a name and click Add to create an empty project."></InfoIcon>
      <b-form @submit.prevent="submitNewProject" inline class="mt-2">
        <b-form-group
            id="email-group"
        >
          <b-row>
            <b-col cols="10">
              <b-form-input
                  id="new-project-name"
                  v-model="form.name"
                  type="text"
                  placeholder="Enter new project name"
                  class="mb-2"
                  required
              ></b-form-input>
            </b-col>
            <b-col cols="2">
              <b-button type="submit" variant="primary">Add</b-button>
              <b-button variant="danger" @click="projectNew"><b-icon icon="x-square"></b-icon></b-button>
            </b-col>
          </b-row>
        </b-form-group>
      </b-form>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "AddNewProject",
  props: {
    value: {
      type: Object,
      required: true
    },
  },
  computed: {
    form: {
      get() {
        return this.value
      },
    },
  },
  methods: {
    submitNewProject() {
      this.$inertia.post(this.$routes.projects(), this.form)
      this.form.name = ''
      this.$emit("project-new")
    },
    projectNew() {
      this.form.name = ''
      this.$emit("project-new")
    }
  }
}
</script>

<style scoped>

</style>