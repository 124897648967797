<template>
  <b-row>
    <h2 class="text-center">Projects Comparison</h2>

    <b-col cols="12" v-if="errors">
      <b-list-group class="mb-2">
        <b-list-group-item variant="danger">
          {{ errors }}
        </b-list-group-item>
      </b-list-group>
    </b-col>
    <b-card no-body style="margin-left:2px" >
      <b-tabs pills card>
      <b-tab title="KPIs Comparison">

    <b-col cols="12">
      <b-row>
        <b-col cols="6" v-if="siiChartData" >
          <SimpleVerticalBarChart
              v-if="siiChartData"
              :chartData="siiChartData"
              :gradientColors="[]"
              :showColorCode="false"
              :showChartLegend="true"
              :axisLabels="{ x: 'Score', y: 'Aggregation Level' }"
              title="Projects SII Comparison"
              id="res1"
          >
          </SimpleVerticalBarChart>
        </b-col>
        <b-col cols="6" v-if="spiChartData" style="margin-top:10px">
          <SimpleVerticalBarChart
              v-if="spiChartData"
              :chartData="spiChartData"
              :gradientColors="[]"
              :showColorCode="false"
              :showChartLegend="true"
              :axisLabels="{ x: 'Score', y: 'Aggregation Level' }"
              title="Projects SPI Comparison"
              id="res2"
          >
          </SimpleVerticalBarChart>
        </b-col>
      </b-row>
    </b-col>


     <span class="row"> 
    <b-col v-if="radarChartData" cols="6 mb-3" style="margin-top:10px"> 
      <RadarChartWithSelect
            title="Dimensions score Projects Comparison"
            selectPrompt="Please select an aggregation level"
            :itemsData="radarChartData"
            id="res3"
          >
          </RadarChartWithSelect>
    </b-col> 
    <b-col v-if="sectorsChartData" cols="6 mb-3"  style="margin-top:10px">
          <RadarChartWithSelect
            title="Sectors score per Aggregation Level"
            selectPrompt="Please select an aggregation level"
            :itemsData="sectorsChartData"
            id="res4"
          >
          </RadarChartWithSelect>
        </b-col>
      </span>

    <b-col cols="12">
      <b-row>
        <b-col cols="6" class="mb-3" v-if="secCompData">
          <ChartWithSelect
              :availableItems="availableSectors"
              :itemsData="secCompData"
              :showFinalSII="false"
              :isStacked="false"
              :isComparing="true"
              :showChartLegend="true"
              isSpiOrSii="spi"
              selectPrompt="Select a sector from the list"
              id="res5"
          ></ChartWithSelect>
        </b-col>
        <b-col cols="6" class="mb-3" v-if="dimCompData">
          <ChartWithSelect
              :availableItems="availableDimensions"
              :itemsData="dimCompData"
              :isStacked="false"
              :isComparing="true"
              :showChartLegend="true"
              :showFinalSII="false"
              selectPrompt="Select a dimension from the list"
              id="res6"
          ></ChartWithSelect>
        </b-col>
      </b-row>
    </b-col>


       <b-col cols="12"  >
            <HorizontalBarChartWithSelect 
              :availableItems="Object.keys(kpisCompareData)"
              :itemsData="kpisCompareData"
              chartType="horizontal"
              selectPrompt="Select a KPI from the list"
              :axisLabels="{x: 'Score', y: ''}"
              :key="`bar_key_${horizontalBarKey}`"
              :isStacked="false"
              :isComparing="true"
              :showChartLegend="true"
              id="res7"
            ></HorizontalBarChartWithSelect>
          </b-col>
        </b-tab>
        <b-tab title="PSIs Comparison"> 
          <!-- PSI Analysis -->
          <b-col cols="12 mb-3" v-if="compareImpactData">
        <SimpleVerticalBarChart
            :chartData="compareImpactData"
            :gradientColors="[]"
            :showChartLegend="true"
            :showColorCode="false"
            :axisLabels="{ x: 'Call Impact Id', y: 'Call Impact Score' }"
            :title="`Call Impact Data Compararison`"
            id="res8"
        >
        </SimpleVerticalBarChart>
      </b-col>
      <b-col cols="12 mb-3" v-if="psisCompareData">
          <SimpleBarChart
              :chartData="psisCompareData"
              :gradientColors="[]"
              :showColorCode="false"
              :averageScoreOption="false"
              :isComparing="true"
              :showChartLegend="true"
              title="PSIs Scores Comparison"
              :axisLabels="{x: 'Score', y: ''}"
              :key="`bar_key_${horizontalBarKey}`"
              id="res9"
          >
          </SimpleBarChart>
        </b-col>
      </b-tab>
      </b-tabs>
      </b-card>
  </b-row>
</template>

<script>
import MainLayout from '@/Layouts/Main'
import SimpleVerticalBarChart from "@/Charts/simple/SimpleVerticalBarChart";
import RadarChartWithSelect from "@/Charts/select/RadarChartWithSelect";
import ChartWithSelect from "@/Charts/select/ChartWithSelect";
import SimpleBarChart from "@/Charts/simple/SimpleBarChart";
import HorizontalBarChartWithSelect from "@/Charts/select/HorizontalBarChartWithSelect";

export default {
  name: "ProjectsCompare",
  layout: MainLayout,
  components: {
    SimpleVerticalBarChart,
    RadarChartWithSelect,
    ChartWithSelect,
    SimpleBarChart,
    HorizontalBarChartWithSelect
  },
  data() {
    return {
      horizontalBarKey: 1,
    }
  },
  props: {
    siiChartData: {
      type: Object,
      required: false
    },
    spiChartData:{
      type: Object,
      required: false
    },
    radarChartData:{
      type: Object,
      required: false
    },
    sectorsChartData:{
      type: Object,
      required: false
    },
    psisCompareData:{
      type: Object,
      required: false
    },
    compareImpactData:{
      type: Object,
      required: false
    },
    // // // //---------
    kpisCompareData:{
      type: Object,
      required: false
    },
    secCompData:{
      type: Object,
      required: false
    },
    // // // //-----------------
    availableSectors:{
      type: Array,
      required: false
    },
    availableDimensions:{
      type: Array,
      required: false
    },
    dimCompData: {
      type: Object,
      required: false
    },
    errors: {
      type: String,
      required: false
    }
  }
}
</script>

<style scoped>

</style>