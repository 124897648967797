<template>
  <div class="select-chart-container" :id="id">
    <b-row>
      <b-col cols="12">
        <ChartTitle :title= title></ChartTitle>
      </b-col>

      <b-col cols="12" v-if="!isComparing">
            <AverageScore 
              colorTable = "gradientColors"
              :averageScoreOption = averageScoreOption
              legendItemTitle = "Project Performance Index (PPI)"
              :gradientColors = gradientColors></AverageScore>
      </b-col>

      <b-col cols="12">
        <HorizontalBarChart
            v-if="chartData"
            :chartData="chartDataCopy"
            :options="options"
            :aspect-ratio="aspectRatio"
            class="bar-chart-plot-full-width"
        >
        </HorizontalBarChart>
      </b-col>
    </b-row>
    <b-button @click="printChart(id)" style="margin-left:10px">Download graph</b-button>
  </div>
</template>

<script>
// This file includes a custom title and custom legend
// for bar charts
import HorizontalBarChart from "../base/HorizontalBarChart";
import ChartTitle from "../components/ChartTitle";
import AverageScore from "../components/AverageScore";
import Exporter from "vue-chartjs-exporter";

export default {
  name: "ChartContainer",
  components: {
    HorizontalBarChart,
    ChartTitle,
    AverageScore
  },
  props: {
    title: {
      type: String,
      default: null,
      required: false
    },
    chartData: {
      type: Object,
      required: true
    },
    gradientColors: {
      type: Array,
      required: false
    },
    showChartLegend: {
      type: Boolean,
      required: false,
      default: false
    },
    averageValue: {
      type: Number,
      required: false,
      default: null
    },
    averageScoreOption:{
      type: Boolean,
      required: false,
      default: true
    },
    axisLabels: {
      type: Object,
      required: false,
      default() {
        return {x: '', y: ''}
      }
    },
    id: {
      type: String,
      required: false
    },
    isComparing: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {}
  },
  computed: {
    aspectRatio() {
      return this.psiChartAspectRatio(this.chartData.labels.length)
    },
    options() {
      return {
        animation: { duration: 1 },
        legend: {
          display: this.showChartLegend
        },
        scales: {
          xAxes: [{
            id: 'x-axis-0',
            scaleLabel: {
              display: true,
              labelString: this.axisLabels.x,
              fontSize: 16,
              fontColor: 'orange',
            },
            ticks: {
              beginAtZero: true,
              max: 5,
              fontSize: 16
            }
          }, {
            id: 'x-axis-1',
            position: 'top',
            type: 'linear',
            scaleLabel: {
              display: true,
              labelString: this.axisLabels.x,
              fontSize: 16,
              fontColor: 'orange',
            },
            ticks: {
              beginAtZero: true,
              max: 5,
              fontSize: 16
            }
          }],
          yAxes: [{
            ticks: {
              fontSize: 16
            }
          }]
        },
        annotation: {
          annotations: [
            this.annotationObject('mean', this.averageValue, 'vertical', 'red')
          ],
        }
      }
    },
    chartDataCopy() {
      return this.deepCopy(this.chartData)
    },
  },
  methods: {
    printChart(id)
  {
    let graphical = document.getElementById(id);
    const exp = new Exporter([graphical]);
    exp.export_pdf().then((pdf) => pdf.save("charts.pdf"));
  }
  }
}
</script>

<style scoped>

</style>