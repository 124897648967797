<template>
  <div  v-if="projectsCompareList.length > 0">
    <b-row class="mb-2">
      <b-col>
        <b-row>
          <b-col cols="10">
              <table class="compare-table">
                <thead>
                <tr>
                  <th colspan="3">Projects to be compared</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="project in projectsCompareList" :key="project.id">
                  <td style="width: 10%">{{ project.name }}</td>
                  <td style="width: 80%">{{ project.id }}</td>
                  <td
                      class="remove-comparable-btn"
                      @click="removeComparable(project.id)"
                  ><b-icon icon="x-square" ></b-icon></td>
                </tr>
                </tbody>
              </table>
            </b-col>
            <b-col cols="2">
              <b-button v-if="projectsCompareList.length > 1" class="compare-btn"> 
                <inertia-link
                  v-if="hasLessThanThreeProjects()"
                    :href="this.$routes.projects_compare({'project1': getProject(0).id, 'project2': getProject(1).id})"
                >
                  Compare
                </inertia-link>
                <inertia-link
                  v-else-if="hasLessThanFourProjects()"
                    :href="this.$routes.projects_compare({'project1': getProject(0).id, 'project2': getProject(1).id, 'project3': getProject(2).id})"
                >
                  Compare
                </inertia-link>
                <inertia-link
                  v-else-if="hasFourProjects()"
                    :href="this.$routes.projects_compare({'project1': getProject(0).id, 'project2': getProject(1).id, 'project3': getProject(2).id, 'project3': getProject(2).id, 'project4': getProject(3).id})"
                >
                  Compare
                </inertia-link>
              </b-button>
              <b-button variant="danger" @click="comparisonEnd" style="padding: 10px">Close</b-button>
            </b-col>
        </b-row>
    </b-col>
  </b-row>
</div>
</template>

<script>
export default {
  name: "ComparableProjectsList",
  props: {
    projectsCompareList: {
      type: Array,
      required: true
    }
  },
  methods: {
    getProject(index) {
      return this.projectsCompareList[index]
    },
    hasLessThanThreeProjects () {
      return this.projectsCompareList.length < 3 && this.projectsCompareList.length > 0 
    },
    hasLessThanFourProjects(){
      return this.projectsCompareList.length < 4 && this.projectsCompareList.length > 0 
    },
    hasFourProjects(){
      return this.projectsCompareList.length = 4 
    },
    removeComparable(id) {
      const projectIndex = this.projectsCompareList.findIndex(p => p.id === id)
      this.projectsCompareList.splice(projectIndex, 1)
    },
    comparisonEnd(){
      this.projectsCompareList = []
      this.$emit("comparison-end")
    }
  }
}
</script>

<style scoped>

</style>