<template>
  <div class="select-chart-container" :id="id">
    <b-row>
      <b-col cols="5" class="mb-2 m-2">
        <FormSelect
            :availableItems="availableItems"
            @update-data="updateData"
            :selectedItem="selectedItem"
            :selectPrompt="selectPrompt"
            ></FormSelect>
      </b-col>
      <b-col v-if="showFinalSII" cols="5" class="mb-2 m-2">
        <FormCheckbox
              :enabledSiiScores="enableSiiScores"
              :isSpiOrSii="isSpiOrSii"
              @update-data="updateCheckboxData"
              ></FormCheckbox> 
      </b-col>
      <b-col cols="12">
        <h5 v-if="selectedItem" style="text-align: center; font-size: 22px">{{ clearAndCapitalize(selectedItem) }}</h5>
        <h5 v-else>Please select an item from the list</h5>
      </b-col>
      <b-col cols="12">
        <BarChart
            v-if="itemChartData && chartType === 'vertical'"
            :chartData="itemChartData"
            :options="chart.options"
            class="select-chart-plot"
        ></BarChart>

        <HorizontalBarChart
            v-if="itemChartData && chartType === 'horizontal'"
            :chartData="itemChartData"
            :options="chart.options"
            class="select-chart-plot"
        >
        </HorizontalBarChart>

      </b-col>
      <b-col cols="12" v-if="!isComparing">
        <AverageScore 
              colorTable = "activeLevels"
              :showFinalSII="showFinalSII"
              :activeLevels="activeLevels"
              :colors="colors"
              :averageScores="averageScores"
              :isSpiOrSii="isSpiOrSii"
            ></AverageScore>
      </b-col>
    </b-row>
    <b-button @click="printChart(id)" style="margin-left:10px">Download graph</b-button>
  </div>
</template>

<script>
import BarChart from "../base/BarChart";
import HorizontalBarChart from "../base/HorizontalBarChart";
import AverageScore from "../components/AverageScore";
import FormSelect from "../components/FormSelect";
import FormCheckbox from "../components/FormCheckbox";
import Exporter from "vue-chartjs-exporter";

export default {
  name: "ChartWithSelect",
  components: {
    BarChart,
    HorizontalBarChart,
    AverageScore,
    FormSelect,
    FormCheckbox,
    Exporter
  },
  props: {
    selectPrompt: {
      type: String,
      required: false,
      default: 'Please select an item from the list'
    },
    availableItems: {
      type: Array,
      required: true
    },
    itemsData: {
      type: Object,
      required: true
    },
    averageScores: {
      type: Object,
      required: false
    },
    showFinalSII: {
      type: Boolean,
      required: false,
      default: false
    },
    isSpiOrSii: {
      type: String,
      required: false,
      default: 'sii'
    },
    chartType: {
      type: String,
      required: false,
      default: 'vertical'
    },
    id: {
      type: String,
      required: false
    },
    isStacked: {
      type: Boolean,
      required: false,
      default: true
    },
    isComparing: {
      type: Boolean,
      required: false,
      default: false
    },
    showChartLegend: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      enableSiiScores: false,
      itemChartData: null,
      selectedItem: null,
      activeLevels: [],
      colors: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)',
        'rgba(255, 205, 86, 1)', 'rgba(75, 192, 192, 1)'],
    }
  },
    computed: {
      chart() {
        return {
            options: {
              responsive: true,
              maintainAspectRatio:false,
              legend: {
                display: this.showChartLegend
              },
              scales: {
                xAxes: [{
                  stacked: this.isStacked,
                  id: "bar-x-axis1",
                  ticks: {
                    fontSize: 16
                  }
                }, {
                  display: false,
                  stacked: true,
                  id: "bar-x-axis2",
                  // these are needed because the bar controller defaults set only the first x axis properties
                  type: 'category',
                  gridLines: {
                    offsetGridLines: true
                  },
                  offset: true,
                }],
                yAxes: [{
                  stacked: false,
                  ticks: {
                    beginAtZero: true,
                    max: 5,
                    fontSize: 16
                  },
                }]

              },
              annotation: {
                annotations: [],
              }
            },
        }
      },
       averageType () {
        if (this.isSpiOrSii === 'sii') return 'SII'
        else return 'SPI'
       }
  },
  mounted () {
    Chart.defaults.global.defaultFontColor = 'white'
    this.selectedItem = this.availableItems[0]
    this.updateData(this.selectedItem)
  },
  methods: {
    updateData (item) {
      const colors = ['red', 'blue', 'yellow', 'green']
      const itemData = this.itemsData[item]['data']
      this.chart.options.annotation.annotations = []
      this.activeLevels = []
      this.selectedItem=item
      this.averageDataset = {
        label: 'Average Score',
        data: [],
        backgroundColor: [],
        xAxisID: 'bar-x-axis2',
        borderWidth: 1,
        barThickness: 15,
      }
      for (let i = 0; i < itemData.labels.length; i++) {
        // this.chart.options.annotation.annotations.push(this.annotationObject(itemData.labels[i],
        // this.averageScores[itemData.labels[i]], colors[i]))
        this.activeLevels.push(itemData.labels[i])
        if (this.showFinalSII && this.enableSiiScores) {
          this.averageDataset.data.push(this.averageScores[itemData.labels[i].toLowerCase()])
          this.averageDataset.backgroundColor.push(this.colors[i])
        }
      }
      this.itemChartData = this.deepCopy(this.itemsData[item]['data'])
      this.itemChartData.datasets.unshift(this.averageDataset)
    },
    updateCheckboxData(item) {
      this.enableSiiScores=item
      this.updateData(this.selectedItem)
    },
    printChart(id)
  {
    let graphical = document.getElementById(id);
    const exp = new Exporter([graphical]);
    exp.export_pdf().then((pdf) => pdf.save("charts.pdf"));
  }
    
  }
}
</script>

<style scoped>

</style>